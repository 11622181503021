import React from 'react';
import {InputText} from "primereact/inputtext";
import {Row, Col} from 'reactstrap';
import {FormModel} from "../api/FormModel";
import Label from "reactstrap/lib/Label";

type ClientFormReadonlyProps = {
    model: FormModel
}

export const ClientFormReadonly: React.FC<ClientFormReadonlyProps> = ({model}) => {

    return (
        <>
            <Row>
                <Col>
                    <Label>Imie i nazwisko/Nazwa firmy</Label>
                    <InputText readOnly={true} className="form-control" value={model.client.name}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>Telefon</Label>
                    <InputText readOnly={true} className="form-control" value={model.client.phone}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>Email</Label>
                    <InputText readOnly={true} className="form-control" value={model.client.email}/>
                </Col>
            </Row>
        </>
    )
}