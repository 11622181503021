import React from 'react';
import {SelectButton} from 'primereact/selectbutton';
import {Col, Row, Label} from "reactstrap";
import {AvGroup, AvInput, AvFeedback} from "availity-reactstrap-validation";
import {MetalType} from "../../api/FormModel";

type MetalFormProps = {
    metal: MetalType | undefined,
    onMetalChange: (type: MetalType) => void
}

export const MetalForm: React.FC<MetalFormProps> = ({metal, onMetalChange}) => {

    const availableTypes = Object.keys(MetalType).map(key => {
        return {
            label: MetalType[key as keyof typeof MetalType],
            value: key
        }
    });

    return (
        <>
            <Row className="my-3">
                <Col>
                    <h2>Wybierz rodzaj metalu wykonanych elementów (opcjonalne)</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AvGroup className="m-0">
                        <AvInput name="metal"
                                 className="d-flex flex-column" tag={SelectButton}
                                 value={metal}
                                 onChange={(e: { value: MetalType; }) => onMetalChange(e.value)}
                                 optionLabel="label"
                                 optionValue="value"
                                 options={availableTypes}
                        />
                    </AvGroup>
                </Col>
            </Row>
        </>
    )
}