import React, {useRef} from 'react';
import {Col, Row} from "reactstrap";
import {AvField, AvGroup, AvInput} from "availity-reactstrap-validation";
import "./StairsTypeForm.css"
import {InputTextarea} from "primereact/inputtextarea";
import {FileUpload} from "primereact/fileupload";
import {InputText} from "primereact/inputtext";
import Label from "reactstrap/lib/Label";
import {config} from '../config';
import {Message} from 'primereact/message';

type OthersFormProps = {
    railings: string | undefined,
    onRailingsChange: (name: string) => void,
    comment: string | undefined
    onCommentChange: (name: string) => void
    images: File[] | undefined
    onImagesChange: (images: File[]) => void
    galleryImageId: number | undefined
    onGalleryImageIdChange: (images: number | undefined) => void
    showSelectFilesAgain: boolean
}

// noinspection JSUnusedLocalSymbols
export const OthersForm: React.FC<OthersFormProps> = ({railings, onRailingsChange, comment, onCommentChange, images, onImagesChange, galleryImageId, onGalleryImageIdChange, showSelectFilesAgain}) => {

    const fileUploadRef = useRef<FileUpload>(null)

    return (
        <>
            <Row className="my-3">
                <Col>
                    <h2>Wprowadź pozostałe informacje (opcjonalne)</h2>
                    {
                        showSelectFilesAgain &&
                        <Message className="d-block font-weight-bold" severity="warn" text="Proszę ponownie wybrać pliki jeżeli były wybrane!"/>
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    <AvField name="railings"
                             label="Opis balustrady"
                             rows={3}
                             inputClass="form-control" tag={InputTextarea}
                             value={railings}
                             validate={{
                                 pattern: {
                                     value: /^[A-Za-z0-9ęóąśłżźćńĘÓĄŚŁŻŹĆŃ!@#$%^&*()\-_+=|\\\[\]{};:'",./?<>` „”\n]*$/u,
                                     errorMessage: 'Niepoprawny opis!'
                                 },
                                 maxLength: {value: 5000, errorMessage: 'Maksymalna długość to 5000 znaków!'}
                             }}
                             onChange={(e: { target: { value: string; }; }) => onRailingsChange(e.target.value)}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AvField name="comment"
                             label="Komentarz"
                             rows={3}
                             validate={{
                                 pattern: {
                                     value: /^[A-Za-z0-9ęóąśłżźćńĘÓĄŚŁŻŹĆŃ!@#$%^&*()\-_+=|\\\[\]{};:'",./?<>` „”\n]*$/u,
                                     errorMessage: 'Niepoprawny komentarz!'
                                 },
                                 maxLength: {value: 5000, errorMessage: 'Maksymalna długość to 5000 znaków!'}
                             }}
                             inputClass="form-control" tag={InputTextarea}
                             value={comment}
                             onChange={(e: { target: { value: string; }; }) => onCommentChange(e.target.value)}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FileUpload
                        ref={fileUploadRef}
                        name="images" multiple accept="image/*"
                        className="form-group"
                        onRemove={(e) => {
                            if (fileUploadRef.current) {
                                const files = fileUploadRef.current.state.files;
                                onImagesChange([...files].filter(s => !s.name.includes(e.file.name)));
                            }
                        }}
                        onSelect={() => fileUploadRef.current && onImagesChange(fileUploadRef.current.state.files)}
                        chooseLabel="Wybierz zdjęcia do dodania"/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AvGroup className="m-0">
                        <Label for="galleryImageId">Podaj numer zdjęcia z <a target="_blank" href={config.galleryUrl}>galerii
                            schodów</a></Label>
                        <AvInput name="galleryImageId"
                                 tag={InputText}
                                 keyfilter="pint"
                                 className="form-control"
                                 value={galleryImageId}
                                 onChange={(e: { target: { value: string; }; }) => onGalleryImageIdChange(e.target.value ? parseInt(e.target.value) : undefined)}/>
                    </AvGroup>
                </Col>
            </Row>
        </>
    )
}