import React from 'react';
import {SelectButton} from 'primereact/selectbutton';
import {Col, Row} from "reactstrap";
import {AvGroup, AvInput, AvFeedback} from "availity-reactstrap-validation";
import "../StairsTypeForm.css"
import {Kind} from '../../api/FormModel';

type StairsKindFormProps = {
    kind: Kind | undefined,
    onKindChange: (type: Kind) => void
}

export const StairsKindForm: React.FC<StairsKindFormProps> = ({kind, onKindChange}) => {

    const availableKinds = Object.keys(Kind).map(key => {
        return {
            label: Kind[key as keyof typeof Kind],
            value: key
        }
    });

    const kindTemplate = (item: { label: string, value: string }) => {
        const logoPath = 'assets/' + item.value.toLowerCase() + '.gif';
        return (
            <div className="p-3" style={{textAlign: 'center'}}>
                <img alt={item.label} src={logoPath} style={{width: '100%'}}/>
                <div className="mt-1">{item.label}</div>
            </div>
        )
    }

    return (
        <>
            <Row className="my-3">
                <Col>
                    <h2>Wybierz rodzaj schodów (opcjonalne)</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AvGroup className="m-0">
                        <AvInput name="stairsType"
                                 label="Rodzaj schodów"
                                 className="App-StairsType-Container" tag={SelectButton}
                                 value={kind}
                                 onChange={(e: { value: Kind; }) => onKindChange(e.value)}
                                 itemTemplate={kindTemplate}
                                 optionLabel="label"
                                 optionValue="value"
                                 options={availableKinds}
                        />
                    </AvGroup>
                </Col>
            </Row>
        </>
    )
}