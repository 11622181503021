import React from 'react';
import {InputText} from "primereact/inputtext";
import {Row, Col} from 'reactstrap';
import {FormModel} from "../../api/FormModel";
import Label from "reactstrap/lib/Label";

type ConcreteFormReadonlyProps = {
    model: FormModel
}

export const ConcreteFormReadonly: React.FC<ConcreteFormReadonlyProps> = ({model}) => {

    return (
        <>
            <Row>
                <Col>
                    <Label>Tryb okładania</Label>
                    <InputText readOnly={true} className="form-control"
                               value={model.coveringType}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>Liczba stopni prostych</Label>
                    <InputText readOnly={true} className="form-control" value={model.numOfStraightGrades}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>Długość stopni prostych (cm)</Label>
                    <InputText readOnly={true} className="form-control" value={model.lengthOfStraightGrades}/>
                </Col>
                <Col>
                    <Label>Szerokość stopni prostych (cm)</Label>
                    <InputText readOnly={true} className="form-control" value={model.widthOfStraightGrades}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>Liczba stopni zabiegowych</Label>
                    <InputText readOnly={true} className="form-control" value={model.numOfTreatmentSteps}/>
                </Col>
                <Col>
                    <Label>Liczba podestów</Label>
                    <InputText readOnly={true} className="form-control" value={model.numOfPlatforms}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>Liczba zdjęć konstrukcji betonowej</Label>
                    <InputText readOnly={true} className="form-control"
                               value={model.concreteConstructionImages ? model.concreteConstructionImages.length : 0}/>
                </Col>
            </Row>
        </>
    )
}