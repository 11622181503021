import React from 'react';
import {InputText} from "primereact/inputtext";
import {Row, Col} from 'reactstrap';
import {CeillingHoleType, FormModel, MetalType} from "../../api/FormModel";
import Label from "reactstrap/lib/Label";

type SpiralFormReadonlyProps = {
    model: FormModel
}

export const SpiralFormReadonly: React.FC<SpiralFormReadonlyProps> = ({model}) => {


    const surroundingWallsStr: string =
        (model.surroundingWalls.left ? "lewo, " : '') +
        (model.surroundingWalls.top ? "góra, " : '') +
        (model.surroundingWalls.right ? "prawo, " : '') +
        (model.surroundingWalls.bottom ? "dół, " : '');

    return (
        <>
            <Row>
                <Col>
                    <Label>Rodzaj metalu</Label>
                    <InputText readOnly={true} className="form-control" value={MetalType[model.metalType as unknown as keyof typeof MetalType]}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>Typ otworu stropowego</Label>
                    <InputText readOnly={true} className="form-control" value={model.ceillingHoleType}/>
                </Col>
                {
                    model.ceillingHoleType as CeillingHoleType === CeillingHoleType.Rectangular &&
                    <>
                        <Col>
                            <Label>Długość (cm)</Label>
                            <InputText readOnly={true} className="form-control" value={model.ceilingHoleReactangular.width}/>
                        </Col>
                        <Col>
                            <Label>Szerokość (cm)</Label>
                            <InputText readOnly={true} className="form-control" value={model.ceilingHoleReactangular.length}/>
                        </Col>
                    </>
                }
                {
                    model.ceillingHoleType as CeillingHoleType === CeillingHoleType.Circular &&
                    <Col>
                        <Label>Średnica (cm)</Label>
                        <InputText readOnly={true} className="form-control" value={model.ceilingHoleCircular.diameter}/>
                    </Col>
                }
                {
                    model.ceillingHoleType as CeillingHoleType === CeillingHoleType.Other &&
                    <Col>
                        <Label>Liczba szkiców</Label>
                        <InputText readOnly={true} className="form-control"
                                   value={model.ceilingHoleOther.sketches ? model.ceilingHoleOther.sketches?.length : 0}/>
                    </Col>
                }
            </Row>
            <Row>
                <Col>
                    <Label>Ściany okalające schody</Label>
                    <InputText readOnly={true} className="form-control" value={surroundingWallsStr}/>
                </Col>
            </Row>
        </>
    )
}