import {CeillingHoleType, FormModel, Type} from "./FormModel";
import {
    RodzajMetalu,
    Schody,
    SchodyBetonowe,
    SchodySamonosne,
    SchodySpiralne,
    TypPodstopni,
    TypSamonosnych
} from "./FormDTO";

export const transform = (formDto: FormModel): Schody => {
    let unsupported: SchodySamonosne | null = null;
    let spiral: SchodySpiralne | null = null;
    let concrete: SchodyBetonowe | null = null;
    switch (Type[formDto.type as unknown as keyof typeof Type]) {
        case Type.Unsupported:
            unsupported = {
                typ: TypSamonosnych[formDto.kind as unknown as keyof typeof TypSamonosnych],
                wysokoscKondygnacji: formDto.storeyHeight,
                gruboscStropu: formDto.slabThickness,
                otworProstokatny: formDto.ceillingHoleType === CeillingHoleType.Rectangular ? {
                    dlugosc: formDto.ceilingHoleReactangular.length,
                    szerokosc: formDto.ceilingHoleReactangular.width,
                } : undefined,
                otworOkragly: formDto.ceillingHoleType === CeillingHoleType.Circular ? {
                    srednica: formDto.ceilingHoleCircular.diameter,
                } : undefined,
                otworInny: formDto.ceillingHoleType === CeillingHoleType.Other ? true : undefined,
                ukladScian: {
                    lewo: formDto.surroundingWalls.left || false,
                    gora: formDto.surroundingWalls.top || false,
                    prawo: formDto.surroundingWalls.right || false,
                    dol: formDto.surroundingWalls.bottom || false,
                },
                podstopnie: formDto.risers === undefined ? undefined : (formDto.risers ? TypPodstopni.Podstopnie : TypPodstopni.BezPodstopni)
            }
            break;
        case Type.Spiral:
            spiral = {
                rodzajMetalu: RodzajMetalu[formDto.metalType as unknown as keyof typeof RodzajMetalu],
                otworProstokatny: formDto.ceillingHoleType === CeillingHoleType.Rectangular ? {
                    dlugosc: formDto.ceilingHoleReactangular.length,
                    szerokosc: formDto.ceilingHoleReactangular.width,
                } : undefined,
                otworOkragly: formDto.ceillingHoleType === CeillingHoleType.Circular ? {
                    srednica: formDto.ceilingHoleCircular.diameter,
                } : undefined,
                otworInny: formDto.ceillingHoleType === CeillingHoleType.Other ? true : undefined,
                ukladScian: {
                    lewo: formDto.surroundingWalls.left || false,
                    gora: formDto.surroundingWalls.top || false,
                    prawo: formDto.surroundingWalls.right || false,
                    dol: formDto.surroundingWalls.bottom || false,
                }
            }
            break;
        case Type.Concrete:
            concrete = {
                dlugoscStopniProstych: formDto.lengthOfStraightGrades,
                iloscStopniProstych: formDto.numOfStraightGrades,
                iloscStopniZabiegowych: formDto.numOfTreatmentSteps,
                iloscPodestow: formDto.numOfPlatforms,
                szerokoscStopniProstych: formDto.widthOfStraightGrades,
                trybOkladania: formDto.coveringType
            }
            break;
    }


    return {
        daneOsobowe: formDto.client.name || '',
        telefon: formDto.client.phone || '',
        email: formDto.client.email,
        betonowe: concrete,
        samonosne: unsupported,
        spiralne: spiral,
        rodzajDrewna: formDto.woodType,
        rodzajWykonczenia: formDto.finishing,
        balustrady: formDto.railings || '',
        komentarz: formDto.comment || '',
        adres: formDto.address.addressLine + ' ' + formDto.address.postalCode + ' ' + formDto.address.city,
        numerZdjeciaGaleria: formDto.galleryImageId
    }

}