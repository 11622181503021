import React from 'react';
import {InputText} from "primereact/inputtext";
import {Col, Row} from 'reactstrap';
import {FormModel, Type} from "../api/FormModel";
import Label from "reactstrap/lib/Label";
import {InputTextarea} from "primereact/inputtextarea";
import {SpiralFormReadonly} from "./spiral/SpiralFormReadonly";
import {ConcreteFormReadonly} from './concrete/ConcreteFormReadonly';
import {UnsupportedFormReadonly} from './unsupported/UnsupportedFormReadonly';

type ClientFormReadonlyProps = {
    model: FormModel
}

export const StairsFormReadonly: React.FC<ClientFormReadonlyProps> = ({model}) => {

    return (
        <>
            <Row>
                <Col>
                    <Label>Typ schodów</Label>
                    <InputText readOnly={true} className="form-control" value={Type[model.type as unknown as keyof typeof Type]}/>
                </Col>
            </Row>
            {
                Type[model.type as unknown as keyof typeof Type] === Type.Unsupported &&
                <UnsupportedFormReadonly model={model}/>
            }
            {
                Type[model.type as unknown as keyof typeof Type] === Type.Spiral &&
                <SpiralFormReadonly model={model}/>
            }
            {
                Type[model.type as unknown as keyof typeof Type] === Type.Concrete &&
                <ConcreteFormReadonly model={model}/>
            }
            <Row>
                <Col>
                    <Label>Rodzaj drewna</Label>
                    <InputText readOnly={true} className="form-control" value={model.woodType}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>Wykończenia elementów drewnianych</Label>
                    <InputText readOnly={true} className="form-control"
                               value={model.finishing}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>Opis balustrady</Label>
                    <InputTextarea rows={2} readOnly={true} className="form-control" value={model.railings}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>Komentarz</Label>
                    <InputTextarea rows={2} readOnly={true} className="form-control" value={model.comment}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>Liczba załączonych zdjęć</Label>
                    <InputText readOnly={true} className="form-control" value={model.images ? model.images.length : 0}/>
                </Col>
            </Row>
        </>
    )
}