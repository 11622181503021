import React from 'react';
import {SelectButton} from 'primereact/selectbutton';
import {Col, Label, Row} from "reactstrap";
import {AvGroup, AvInput, AvFeedback} from "availity-reactstrap-validation";
import "../StairsTypeForm.css"
import {
    SurroundingWalls
} from '../../api/FormModel';
import './SurroundingWallsForm.css';

const classNames = require('classnames');

type SurroundingWallsFormProps = {
    walls: SurroundingWalls | undefined,
    onWallsChange: (walls: SurroundingWalls) => void
}

export const SurroundingWallsForm: React.FC<SurroundingWallsFormProps> = ({walls, onWallsChange}) => {

    const transformValueToType = (value: string[]): SurroundingWalls => {
        return {
            left: value.includes("left"),
            right: value.includes("right"),
            top: value.includes("top"),
            bottom: value.includes("bottom")
        }
    }
    const transformTypeToValue = (type: SurroundingWalls | undefined): string[] => {
        let result: string[] = [];
        if (!type) {
            return result;
        }
        if (type.left) {
            result.push("left");
        }
        if (type.right) {
            result.push("right");
        }
        if (type.top) {
            result.push("top");
        }
        if (type.bottom) {
            result.push("bottom");
        }
        return result;
    }

    const availableWalls = [
        {
            label: 'lewa',
            value: 'left'
        },
        {
            label: 'góra',
            value: 'top'
        },
        {
            label: 'prawa',
            value: 'right'
        },
        {
            label: 'dół',
            value: 'bottom'
        }
    ]

    const surroundingWallsRectangeClass = classNames({
        'top-border': walls?.top,
        'bottom-border': walls?.bottom,
        'left-border': walls?.left,
        'right-border': walls?.right
    });

    return (
        <>
            <Row className="my-3">
                <Col>
                    <h2>Wybierz ściany okalające schody (opcjonalne)</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AvGroup>
                        <AvInput name="surroundingWalls"
                                 multiple
                                 className="d-flex flex-column" tag={SelectButton}
                                 value={transformTypeToValue(walls)}
                                 onChange={(e: { value: string[]; }) => {
                                     onWallsChange(transformValueToType(e.value));
                                 }}
                                 optionLabel="label"
                                 optionValue="value"
                                 options={availableWalls}
                        />
                        <AvFeedback>Pole wymagane!</AvFeedback>
                    </AvGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>Podgląd ścian</Label>
                    <div id="surroundingWallsRectange" className={surroundingWallsRectangeClass}/>
                </Col>
            </Row>
        </>
    )
}