export type FormModel = {
    client: Partial<Client>,
    type: Type | undefined,
    woodType: string | undefined,
    kind: Kind | undefined
    storeyHeight: number | undefined
    slabThickness: number | undefined
    ceillingHoleType: CeillingHoleType | undefined
    ceilingHoleReactangular: CeillingHoleRectangular
    ceilingHoleCircular: CeillingHoleCircular
    ceilingHoleOther: CeillingHoleOther
    surroundingWalls: SurroundingWalls
    metalType: MetalType | undefined,
    coveringType: string | undefined,
    numOfStraightGrades: number | undefined,
    lengthOfStraightGrades: number | undefined,
    widthOfStraightGrades: number | undefined,
    numOfTreatmentSteps: number | undefined
    numOfPlatforms: number | undefined
    concreteConstructionImages: File[] | undefined
    risers: boolean | undefined
    finishing: string | undefined,
    railings: string | undefined,
    comment: string | undefined,
    address: Partial<Address>
    images: File[] | undefined
    galleryImageId: number | undefined
}

export type Address = {
    addressLine: string,
    postalCode: string,
    city: string
}

export enum Type {
    Unsupported = "samonośne", Spiral = "spiralne", Concrete = "betonowe"
}

export type Client = {
    name: string,
    phone: string,
    email: string
}

export enum Kind {
    Zab90 = "zab90", Zab180 = "zab180", Zab2x90 = "zab2x90", Zab3x90 = "zab3x90", Pod180 = "pod180", Proste = "proste"
}

export enum CeillingHoleType {
    Rectangular = "prostokątny", Circular = "okrągły", Other = "inny"
}

export type CeillingHoleRectangular = {
    length: number | undefined,
    width: number | undefined
}

export type CeillingHoleCircular = {
    diameter: number | undefined
}

export type CeillingHoleOther = {
    sketches: File[] | undefined
}

export type SurroundingWalls = {
    left: boolean | undefined
    right: boolean | undefined
    top: boolean | undefined
    bottom: boolean | undefined
}

export enum MetalType {
    stalNierdzewna = "stal nierdzewna", stalMalowanaProszkowo = "stal malowana proszkowo"
}