import React from 'react';
import {Col, Row} from "reactstrap";
import {AvField} from "availity-reactstrap-validation";
import "../StairsTypeForm.css"
import {InputText} from "primereact/inputtext";

type StoreyFormProps = {
    storeyHeight: number | undefined
    onStoreyHeightChange: (storeyHeight: number | undefined) => void
    slabThickness: number | undefined
    onSlabThicknessChange: (slabThickness: number | undefined) => void
}

export const StoreyForm: React.FC<StoreyFormProps> = ({storeyHeight, onStoreyHeightChange, slabThickness, onSlabThicknessChange}) => {

    return (
        <>
            <Row className="my-3">
                <Col>
                    <h2>Wprowadź wysokość kondygnacji i grubość stropu (opcjonalne)</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AvField name="storeyHeight"
                             label="Wysokość kondygnacji od podłogi do podłogi (cm)"
                             inputClass="form-control" tag={InputText}
                             keyfilter={/^[\d,]$/}
                             value={storeyHeight ? storeyHeight.toString().replace('.', ',') : ''}
                             onChange={(e: { target: { value: string; }; }) => onStoreyHeightChange(e.target.value ? parseFloat(e.target.value.replace(',', '.')) : undefined)}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AvField name="slabThickness"
                             groupAttrs={{
                                 className: "m-0"
                             }}
                             label="Grubość stropu (cm)"
                             inputClass="form-control" tag={InputText}
                             keyfilter={/^[\d,]$/}
                             value={slabThickness ? slabThickness.toString().replace('.', ',') : ''}
                             onChange={(e: { target: { value: string; }; }) => onSlabThicknessChange(e.target.value ? parseFloat(e.target.value.replace(',', '.')) : undefined)}/>
                </Col>
            </Row>
        </>
    )
}