import React from 'react';
import {AvField} from 'availity-reactstrap-validation';
import {InputText} from "primereact/inputtext";
import {Row, Col} from 'reactstrap';
import {InputMask} from "primereact/inputmask";

type AddressFormProps = {
    addressLine: string | undefined,
    onAddressLineChange: (name: string) => void,
    postalCode: string | undefined,
    onPostalCodeChange: (name: string) => void,
    city: string | undefined,
    onCityChange: (name: string) => void
}


export const AddressForm: React.FC<AddressFormProps> = ({addressLine, onAddressLineChange, postalCode, onPostalCodeChange, city, onCityChange}) => {

    return (
        <>
            <Row className="my-3">
                <Col>
                    <h2>Wprowadź adres realizacji zamówienia</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AvField name="addressLine"
                             label="Adres*"
                             required
                             inputClass="form-control" tag={InputText}
                             value={addressLine}
                             onChange={(e: { target: { value: string; }; }) => onAddressLineChange(e.target.value)}
                             validate={{
                                 pattern: {
                                     value: /^[A-Za-zęóąśłżźćńĘÓĄŚŁŻŹĆŃ0-9\- .,/()„”]*$/u,
                                     errorMessage: 'Niepoprawny adres!'
                                 },
                                 minLength: {value: 3, errorMessage: 'Minimalna długość to 3 znaki!'},
                                 maxLength: {value: 255, errorMessage: 'Maksymalna długość to 255 znaków!'},
                                 required: {value: true, errorMessage: 'Pole wymagane!'}
                             }}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AvField name="postalCode"
                             label="Kod pocztowy*"
                             required
                             mask="99-999"
                             groupAttrs={{
                                 className: "m-0"
                             }}
                             inputClass="form-control" tag={InputMask}
                             value={postalCode}
                             onChange={(e: { target: { value: string; }; }) => onPostalCodeChange(e.target.value)}
                             validate={{
                                 minLength: {value: 3, errorMessage: 'Minimalna długość to 3 znaki!'},
                                 maxLength: {value: 255, errorMessage: 'Maksymalna długość to 255 znaków!'},
                                 required: {value: true, errorMessage: 'Pole wymagane!'}
                             }}/>
                </Col>
                <Col>
                    <AvField name="city"
                             label="Miejscowość*"
                             required
                             groupAttrs={{
                                 className: "m-0"
                             }}
                             inputClass="form-control" tag={InputText}
                             value={city}
                             onChange={(e: { target: { value: string; }; }) => onCityChange(e.target.value)}
                             validate={{
                                 pattern: {
                                     value: /^[A-Za-zęóąśłżźćńĘÓĄŚŁŻŹĆŃ\- ():]*$/u,
                                     errorMessage: 'Niepoprawna miejscowość!'
                                 },
                                 minLength: {value: 3, errorMessage: 'Minimalna długość to 3 znaki!'},
                                 maxLength: {value: 255, errorMessage: 'Maksymalna długość to 255 znaków!'},
                                 required: {value: true, errorMessage: 'Pole wymagane!'}
                             }}/>
                </Col>
            </Row>
        </>
    )
}