import React, {useRef} from 'react';
import {Col, Row} from "reactstrap";
import "../StairsTypeForm.css"
import {FileUpload} from "primereact/fileupload";
import {Message} from "primereact/message";

type ConstructionFormProps = {
    concreteConstructionImages: File[] | undefined
    onConcreteConstructionImagesChange: (images: File[]) => void
    showSelectFilesAgain: boolean
}

// noinspection JSUnusedLocalSymbols
export const ConstructionForm: React.FC<ConstructionFormProps> = ({concreteConstructionImages, onConcreteConstructionImagesChange, showSelectFilesAgain}) => {

    const fileUploadRef = useRef<FileUpload>(null)

    return (
        <>
            <Row className="my-3">
                <Col>
                    <h2>Załącz zdjęcia konstrukcji betonowej (opcjonalne)</h2>
                    {
                        showSelectFilesAgain &&
                        <Message className="d-block font-weight-bold" severity="warn" text="Proszę ponownie wybrać pliki jeżeli były wybrane!"/>
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    <FileUpload
                        ref={fileUploadRef}
                        name="concreteConstructionImages" multiple accept="image/*"
                        onRemove={(e) => {
                            if (fileUploadRef.current) {
                                onConcreteConstructionImagesChange([...fileUploadRef.current.state.files]
                                    .filter(s => !s.name.includes(e.file.name)));
                            }
                        }}
                        onSelect={() => {
                            if (fileUploadRef.current) {
                                onConcreteConstructionImagesChange([...fileUploadRef.current.state.files])
                            }
                        }}
                        chooseLabel="Wybierz zdjęcia konstrukcji betonowej"/>
                </Col>
            </Row>
        </>
    )
}