const getServerUrlBasedOnLocation = (externalDomainPrefix: string, internalDomainPort: string, prefix: string) => {
    const loc = window.location;
    let hostUrl = loc.protocol + "//";
    if (/[a-zA-Z]/.test(loc.hostname) && loc.hostname !== 'localhost') {
        hostUrl += loc.host + externalDomainPrefix;
    } else {
        hostUrl += loc.hostname + ":" + internalDomainPort;
    }
    return hostUrl + prefix;
}

const getApiServerUrlBasedOnLocation = () => {
    return getServerUrlBasedOnLocation(process.env.REACT_APP_API_EXTERNAL_DOMAIN_PREFIX || '',
        process.env.REACT_APP_API_INTERNAL_DOMAIN_PORT || '5080',
        process.env.REACT_APP_API_PREFIX || '');
}

export const config = {
    apiUrl: getApiServerUrlBasedOnLocation(),
    galleryUrl: process.env.REACT_APP_GALLERY_URL
};