import React from 'react';
import {InputText} from "primereact/inputtext";
import {Row, Col} from 'reactstrap';
import {CeillingHoleType, FormModel, Kind} from "../../api/FormModel";
import Label from "reactstrap/lib/Label";

type UnsupportedFormReadonlyProps = {
    model: FormModel
}

export const UnsupportedFormReadonly: React.FC<UnsupportedFormReadonlyProps> = ({model}) => {

    const surroundingWallsStr: string =
        (model.surroundingWalls.left ? "lewo, " : '') +
        (model.surroundingWalls.top ? "góra, " : '') +
        (model.surroundingWalls.right ? "prawo, " : '') +
        (model.surroundingWalls.bottom ? "dół, " : '');

    return (
        <>
            <Row>
                <Col>
                    <Label>Rodzaj schodów</Label>
                    <InputText readOnly={true} className="form-control" value={Kind[model.kind as unknown as keyof typeof Kind]}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>Wysokość kondygnacji (cm)</Label>
                    <InputText readOnly={true} className="form-control" value={model.storeyHeight}/>
                </Col>
                <Col>
                    <Label>Grubość stropu (cm)</Label>
                    <InputText readOnly={true} className="form-control" value={model.slabThickness}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>Typ otworu stropowego</Label>
                    <InputText readOnly={true} className="form-control" value={model.ceillingHoleType}/>
                </Col>
                {
                    model.ceillingHoleType as CeillingHoleType === CeillingHoleType.Rectangular &&
                    <>
                        <Col>
                            <Label>Długość (cm)</Label>
                            <InputText readOnly={true} className="form-control" value={model.ceilingHoleReactangular.width}/>
                        </Col>
                        <Col>
                            <Label>Szerokość (cm)</Label>
                            <InputText readOnly={true} className="form-control" value={model.ceilingHoleReactangular.length}/>
                        </Col>
                    </>
                }
                {
                    model.ceillingHoleType as CeillingHoleType === CeillingHoleType.Circular &&
                    <Col>
                        <Label>Średnica (cm)</Label>
                        <InputText readOnly={true} className="form-control" value={model.ceilingHoleCircular.diameter}/>
                    </Col>
                }
                {
                    model.ceillingHoleType as CeillingHoleType === CeillingHoleType.Other &&
                    <Col>
                        <Label>Liczba szkiców</Label>
                        <InputText readOnly={true} className="form-control"
                                   value={model.ceilingHoleOther.sketches ? model.ceilingHoleOther.sketches?.length : 0}/>
                    </Col>
                }
            </Row>
            <Row>
                <Col>
                    <Label>Ściany okalające schody</Label>
                    <InputText readOnly={true} className="form-control" value={surroundingWallsStr}/>
                </Col>
                <Col>
                    <Label>Podstopnie</Label>
                    <InputText readOnly={true} className="form-control"
                               value={model.risers === undefined ? '' : (model.risers ? 'tak' : 'nie')}/>
                </Col>
            </Row>
        </>
    )
}