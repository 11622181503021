import React, {useRef} from 'react';
import {SelectButton} from 'primereact/selectbutton';
import {Col, Label, Row} from "reactstrap";
import {AvGroup, AvInput, AvField} from "availity-reactstrap-validation";
import "../StairsTypeForm.css"
import {CeillingHoleCircular, CeillingHoleOther, CeillingHoleRectangular, CeillingHoleType} from '../../api/FormModel';
import {InputText} from "primereact/inputtext";
import {FileUpload} from "primereact/fileupload";
import {Message} from "primereact/message";

type CeillingHoleFormProps = {
    type: CeillingHoleType | undefined,
    onTypeChange: (type: CeillingHoleType) => void
    rectangular: CeillingHoleRectangular
    onRectangularChange: (hole: CeillingHoleRectangular) => void
    circular: CeillingHoleCircular
    onCircularChange: (hole: CeillingHoleCircular) => void
    other: CeillingHoleOther
    onOtherChange: (hole: CeillingHoleOther) => void
    showSelectFilesAgain: boolean
}

export const CeillingHoleForm: React.FC<CeillingHoleFormProps> = ({type, onTypeChange, rectangular, onRectangularChange, circular, onCircularChange, other, onOtherChange, showSelectFilesAgain}) => {

    const fileUploadRef = useRef<FileUpload>(null)

    const availableTypes = Object.keys(CeillingHoleType).map(key => {
        return {
            label: CeillingHoleType[key as keyof typeof CeillingHoleType],
            value: CeillingHoleType[key as keyof typeof CeillingHoleType]
        }
    });

    return (
        <>
            <Row className="my-3">
                <Col>
                    <h2>Wybierz typ otworu stropowego wraz z wymiarami (opcjonalne)</h2>
                    {
                        type as CeillingHoleType === CeillingHoleType.Other && showSelectFilesAgain &&
                        <Message className="d-block font-weight-bold" severity="warn" text="Proszę ponownie wybrać pliki jeżeli były wybrane!"/>
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    <AvGroup>
                        <Label for="type">Typ otworu stropowego</Label>
                        <AvInput name="type"
                                 className="d-flex flex-column" tag={SelectButton}
                                 value={type}
                                 onChange={(e: { value: CeillingHoleType; }) => onTypeChange(e.value)}
                                 optionLabel="label"
                                 optionValue="value"
                                 options={availableTypes}
                        />
                    </AvGroup>
                </Col>
            </Row>
            {
                type as CeillingHoleType === CeillingHoleType.Circular &&
                <Row>
                    <Col>
                        <AvField name="diameter"
                                 label="Średnica (cm)"
                                 keyfilter={/^[\d,]$/}
                                 groupAttrs={{
                                     className: "m-0"
                                 }}
                                 inputClass="form-control" tag={InputText}
                                 value={circular.diameter ? circular.diameter.toString().replace('.', ',') : ''}
                                 onChange={(e: { target: { value: string; }; }) => {
                                     const newCircular: CeillingHoleCircular = {...circular};
                                     newCircular.diameter = e.target.value ? parseFloat(e.target.value.replace(',', '.')) : undefined
                                     onCircularChange(newCircular);
                                 }}/>
                    </Col>
                </Row>
            }
            {
                type as CeillingHoleType === CeillingHoleType.Rectangular &&
                <>
                    <Row>
                        <Col>
                            <AvField name="length"
                                     label="Długość (cm)"
                                     keyfilter={/^[\d,]$/}
                                     groupAttrs={{
                                         className: "m-0"
                                     }}
                                     inputClass="form-control" tag={InputText}
                                     value={rectangular.length ? rectangular.length.toString().replace('.', ',') : ''}
                                     onChange={(e: { target: { value: string; }; }) => {
                                         const newRectangular: CeillingHoleRectangular = {...rectangular};
                                         newRectangular.length = e.target.value ? parseFloat(e.target.value.replace(',', '.')) : undefined;
                                         onRectangularChange(newRectangular);
                                     }}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <AvField name="width"
                                     label="Szerokość (cm)"
                                     keyfilter={/^[\d,]$/}
                                     groupAttrs={{
                                         className: "m-0"
                                     }}
                                     inputClass="form-control" tag={InputText}
                                     value={rectangular.width ? rectangular.width.toString().replace('.', ',') : ''}
                                     onChange={(e: { target: { value: string; }; }) => {
                                         const newRectangular: CeillingHoleRectangular = {...rectangular};
                                         newRectangular.width = e.target.value ? parseFloat(e.target.value.replace(',', '.')) : undefined;
                                         onRectangularChange(newRectangular);
                                     }}/>
                        </Col>
                    </Row>
                </>
            }
            {
                type as CeillingHoleType === CeillingHoleType.Other &&
                <Row>
                    <Col>
                        <FileUpload
                            ref={fileUploadRef}
                            name="images" multiple accept="image/*,application/pdf"
                            onRemove={(e) => {
                                if (fileUploadRef.current) {
                                    const newOther: CeillingHoleOther = {...other};
                                    newOther.sketches = [...fileUploadRef.current.state.files]
                                        .filter(s => !s.name.includes(e.file.name));
                                    onOtherChange(newOther);
                                }
                            }}
                            onSelect={() => {
                                if (fileUploadRef.current) {
                                    const newOther: CeillingHoleOther = {...other};
                                    newOther.sketches = [...fileUploadRef.current.state.files];
                                    onOtherChange(newOther)
                                }
                            }}
                            chooseLabel="Wybierz szkice do dodania"/>
                    </Col>
                </Row>

            }
        </>
    )
}