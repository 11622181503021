import React, {ReactElement} from 'react';
import {Steps} from 'primereact/steps';
import {ClientForm} from './ClientForm';
import {AvForm} from "availity-reactstrap-validation";
import {Button, Col, Container, Row} from 'reactstrap';
import {StairsTypeForm} from "./StairsTypeForm";
import {WoodsTypeForm} from './WoodsTypeForm';
import {FinishingForm} from "./FinishingForm";
import {OthersForm} from './OthersForm';
import {AddressForm} from "./AddressForm";
import {FormModel, Type, CeillingHoleType} from "../api/FormModel";
import {SummaryFormPage} from "./SummaryFormPage";
import {sendForm} from "./FormService";
import {transform} from '../api/FormModelTransformer';
import {StairsKindForm} from './unsupported/StairsKindForm';
import {StoreyForm} from "./unsupported/StoreyForm";
import {CeillingHoleForm} from "./unsupported/CeillingHoleForm";
import {SurroundingWallsForm} from "./unsupported/SurroundingWallsForm";
import {RisersForm} from "./unsupported/RisersForm";
import {MetalForm} from "./spiral/MetalForm";
import {CoveringForm} from "./concrete/CoveringForm";
import {DimensionForm} from "./concrete/DimensionForm";
import {ConstructionForm} from "./concrete/ConstructionForm";
import {ProgressSpinner} from 'primereact/progressspinner';
import {Dialog} from 'primereact/dialog';
import {Message} from 'primereact/message';


const woodsType = [{
    label: "buk",
    value: "buk",
    assetName: "buk"
}, {
    label: "dąb",
    value: "dąb",
    assetName: "dab"
}, {
    label: "jesion",
    value: "jesion",
    assetName: "jesion"
}, {
    label: "sosna",
    value: "sosna",
    assetName: "sosna"
}]

const commonsStepsSuffix: string[] = ['Dane kontaktowe', 'Typ'];
const commonsStepsPrefix: string[] = ['Rodzaj drewna', 'Wykończenie', 'Inne', 'Adres realizacji', 'Podsumowanie'];
const customStepsPlaceholder: string = '...';
const customSteps: { [key: string]: string[] } = {
    "Unsupported": ["Rodzaj", "Kondygnacja", "Otwór stropowy", "Ściany okalające", "Podstopnie"],
    "Spiral": ["Rodzaj metalu", "Otwór stropowy", "Ściany okalające"],
    "Concrete": ["Typ okładania", "Wymiary", "Konstrukcja betonowa"],
};

type FormState = {
    step: number,
    visitedSteps: Set<string>,
    stepsHistory: string[],
    mail: {
        sending: boolean,
        sent: boolean,
        showDialog: boolean
    },
    model: FormModel
}

export const Form: React.FC = () => {

    const [state, setState] = React.useState<FormState>({
        step: 0,
        stepsHistory: ['Dane kontaktowe'],
        visitedSteps: new Set<string>(['Dane kontaktowe']),
        mail: {
            sending: false,
            sent: false,
            showDialog: false
        },
        model: {
            client: {},
            type: undefined,
            kind: undefined,
            storeyHeight: undefined,
            slabThickness: undefined,
            ceillingHoleType: undefined,
            ceilingHoleReactangular: {width: undefined, length: undefined},
            ceilingHoleCircular: {diameter: undefined},
            ceilingHoleOther: {sketches: undefined},
            surroundingWalls: {left: undefined, bottom: undefined, right: undefined, top: undefined},
            metalType: undefined,
            coveringType: undefined,
            numOfStraightGrades: undefined,
            lengthOfStraightGrades: undefined,
            widthOfStraightGrades: undefined,
            numOfTreatmentSteps: undefined,
            numOfPlatforms: undefined,
            concreteConstructionImages: undefined,
            risers: undefined,
            woodType: undefined,
            finishing: undefined,
            railings: undefined,
            comment: undefined,
            address: {},
            images: undefined,
            galleryImageId: undefined
        },
    });

    const stepsComponents: { [key: string]: ReactElement } = {
        'Dane kontaktowe':
            <ClientForm name={state.model.client.name}
                        phone={state.model.client.phone}
                        email={state.model.client.email}
                        onNameChange={e => {
                            const newState = {...state};
                            state.model.client.name = e;
                            setState(newState);
                        }}
                        onEmailChange={e => {
                            const newState = {...state};
                            state.model.client.email = e;
                            setState(newState);
                        }}
                        onPhoneChange={e => {
                            const newState = {...state};
                            state.model.client.phone = e;
                            setState(newState);
                        }}/>,
        'Typ':
            <StairsTypeForm type={state.model.type}
                            onTypeChange={type => {
                                const newState = {...state};
                                state.model.type = type;
                                setState(newState)
                            }}/>,
        'Rodzaj drewna':
            <WoodsTypeForm type={state.model.woodType}
                           avalableTypes={woodsType}
                           onTypeChange={type => {
                               const newState = {...state};
                               state.model.woodType = type;
                               setState(newState)
                           }}/>,
        'Wykończenie':
            <FinishingForm finishing={state.model.finishing}
                           onFinishingChange={finishing => {
                               const newState = {...state};
                               state.model.finishing = finishing;
                               setState(newState)
                           }}/>,
        'Inne':
            <OthersForm
                railings={state.model.railings}
                comment={state.model.comment}
                onRailingsChange={e => {
                    const newState = {...state};
                    newState.model.railings = e;
                    setState(newState);
                }}
                onCommentChange={e => {
                    const newState = {...state};
                    newState.model.comment = e;
                    setState(newState);
                }}
                images={state.model.images}
                onImagesChange={images => {
                    const newState = {...state};
                    newState.model.images = images;
                    setState(newState);
                }}
                galleryImageId={state.model.galleryImageId}
                onGalleryImageIdChange={galleryImageId => {
                    const newState = {...state};
                    newState.model.galleryImageId = galleryImageId;
                    setState(newState);
                }}
                showSelectFilesAgain={state.stepsHistory.filter(step => step === 'Inne').length > 1}
            />,
        'Adres realizacji':
            <AddressForm addressLine={state.model.address.addressLine}
                         postalCode={state.model.address.postalCode}
                         city={state.model.address.city}
                         onAddressLineChange={addressLine => {
                             const newState = {...state};
                             newState.model.address.addressLine = addressLine;
                             setState(newState);
                         }}
                         onPostalCodeChange={postalCode => {
                             const newState = {...state};
                             newState.model.address.postalCode = postalCode;
                             setState(newState);
                         }}
                         onCityChange={city => {
                             const newState = {...state};
                             newState.model.address.city = city;
                             setState(newState);
                         }}/>,
        'Podsumowanie':
            <SummaryFormPage model={state.model}/>,
        'Rodzaj':
            <StairsKindForm kind={state.model.kind}
                            onKindChange={kind => {
                                const newState = {...state};
                                state.model.kind = kind;
                                setState(newState);
                            }}/>,
        'Kondygnacja':
            <StoreyForm slabThickness={state.model.slabThickness}
                        onSlabThicknessChange={slabThickness => {
                            const newState = {...state};
                            state.model.slabThickness = slabThickness;
                            setState(newState);
                        }}
                        storeyHeight={state.model.storeyHeight}
                        onStoreyHeightChange={storeyHeight => {
                            const newState = {...state};
                            state.model.storeyHeight = storeyHeight;
                            setState(newState);
                        }}/>,
        'Otwór stropowy':
            <CeillingHoleForm type={state.model.ceillingHoleType}
                              onTypeChange={ceillingHoleType => {
                                  const newState = {...state};
                                  state.model.ceillingHoleType = ceillingHoleType;
                                  setState(newState);
                              }}
                              circular={state.model.ceilingHoleCircular}
                              onCircularChange={ceilingHoleCircular => {
                                  const newState = {...state};
                                  state.model.ceilingHoleCircular = ceilingHoleCircular;
                                  setState(newState);
                              }}
                              rectangular={state.model.ceilingHoleReactangular}
                              onRectangularChange={ceilingHoleReactangular => {
                                  const newState = {...state};
                                  state.model.ceilingHoleReactangular = ceilingHoleReactangular;
                                  setState(newState);
                              }}
                              other={state.model.ceilingHoleOther}
                              onOtherChange={ceilingHoleOther => {
                                  const newState = {...state};
                                  state.model.ceilingHoleOther = ceilingHoleOther;
                                  setState(newState);
                              }}
                              showSelectFilesAgain={state.stepsHistory.filter(step => step === 'Otwór stropowy').length > 1}
            />,
        'Ściany okalające':
            <SurroundingWallsForm walls={state.model.surroundingWalls}
                                  onWallsChange={walls => {
                                      const newState = {...state};
                                      state.model.surroundingWalls = walls;
                                      setState(newState);
                                  }}/>,
        'Podstopnie':
            <RisersForm risers={state.model.risers}
                        onRisersChange={risers => {
                            const newState = {...state};
                            state.model.risers = risers;
                            setState(newState);
                        }}/>,
        'Rodzaj metalu':
            <MetalForm metal={state.model.metalType}
                       onMetalChange={metalType => {
                           const newState = {...state};
                           state.model.metalType = metalType;
                           setState(newState);
                       }}/>,
        'Typ okładania':
            <CoveringForm covering={state.model.coveringType}
                          onCoveringChange={coveringType => {
                              const newState = {...state};
                              state.model.coveringType = coveringType;
                              setState(newState);
                          }}/>,
        'Wymiary':
            <DimensionForm
                numOfStraightGrades={state.model.numOfStraightGrades}
                lengthOfStraightGrades={state.model.lengthOfStraightGrades}
                widthOfStraightGrades={state.model.widthOfStraightGrades}
                numOfTreatmentSteps={state.model.numOfTreatmentSteps}
                numOfPlatforms={state.model.numOfPlatforms}
                onNumOfStraightGradesChange={numOfStraightGrades => {
                    const newState = {...state};
                    state.model.numOfStraightGrades = numOfStraightGrades;
                    setState(newState);
                }}
                onLengthOfStraightGradesChange={lengthOfStraightGrades => {
                    const newState = {...state};
                    state.model.lengthOfStraightGrades = lengthOfStraightGrades;
                    setState(newState);
                }}
                onWidthOfStraightGradesChange={widthOfStraightGrades => {
                    const newState = {...state};
                    state.model.widthOfStraightGrades = widthOfStraightGrades;
                    setState(newState);
                }}
                onNumOfTreatmentStepsChange={numOfTreatmentSteps => {
                    const newState = {...state};
                    state.model.numOfTreatmentSteps = numOfTreatmentSteps;
                    setState(newState);
                }}
                onNumOfPlatformsChange={numOfPlatforms => {
                    const newState = {...state};
                    state.model.numOfPlatforms = numOfPlatforms;
                    setState(newState);
                }}
            />,
        'Konstrukcja betonowa':
            <ConstructionForm
                concreteConstructionImages={state.model.concreteConstructionImages}
                onConcreteConstructionImagesChange={concreteConstructionImages => {
                    const newState = {...state};
                    state.model.concreteConstructionImages = concreteConstructionImages;
                    setState(newState);
                }}
                showSelectFilesAgain={state.stepsHistory.filter(step => step === 'Konstrukcja betonowa').length > 1}
            />
    }

    const handleNextStep = () => {
        const steps = determineSteps(true);
        const visitedSteps = state.visitedSteps.add(steps[state.step + 1].label);
        const stepsHistory = [...state.stepsHistory, steps[state.step + 1].label];
        setState({
            ...state, step: state.step + 1, visitedSteps, stepsHistory
        });
    };

    const handleBackStep = () => {
        const steps = determineSteps();
        setState({
            ...state, step: state.step - 1, stepsHistory: [...state.stepsHistory, steps[state.step - 1].label]
        });
    };

    const ifShouldSendSketches = () => {
        return (Type[state.model.type as unknown as keyof typeof Type] === Type.Unsupported
            || Type[state.model.type as unknown as keyof typeof Type] === Type.Spiral)
            && state.model.ceillingHoleType as CeillingHoleType === CeillingHoleType.Other
    }

    const ifShouldSendConcreteConstructionImages = () => {
        return Type[state.model.type as unknown as keyof typeof Type] === Type.Concrete;
    }

    const validSubmit = () => {
        if (state.step === determineSteps().length - 1) {
            const newState = {...state};
            newState.mail.sending = true;
            newState.mail.showDialog = true;
            setState(newState);
            sendForm(transform(state.model),
                state.model.images || [],
                ifShouldSendSketches() ? state.model.ceilingHoleOther.sketches || [] : [],
                ifShouldSendConcreteConstructionImages() ? state.model.concreteConstructionImages || [] : [])
                .then((data) => {
                    console.log("data", data);
                    const newState = {...state};
                    newState.mail.sent = true;
                    setState(newState);
                })
                .catch((e) => {
                    console.error("error", e);
                })
                .finally(() => {
                    const newState = {...state};
                    newState.mail.sending = false;
                    setState(newState);
                })
        } else {
            handleNextStep();
        }
    };

    const determineSteps = (forFutureStep: boolean = false) => {
        const customStepsByType: string[] = customSteps[state.model.type || 'Unsupported']
        const finalSteps = commonsStepsSuffix.concat((state.visitedSteps.size + (forFutureStep ? 1 : 0)) < 3 ? customStepsPlaceholder : customStepsByType).concat(commonsStepsPrefix);
        return finalSteps.map((item: string, index: number) => {
            return {label: item, disabled: !state.visitedSteps.has(finalSteps[index])}
        });
    }

    const getStepContent = (step: number) => {
        let steps = determineSteps();
        let label = steps[step].label;
        return stepsComponents[label];
    }

    const renderDialogFooter = () => {
        return (
            <div>
                <Button color="primary" disabled={state.mail.sending} onClick={() => hideDialog()}>Zamknij</Button>
            </div>
        );
    }

    const hideDialog = () => {
        const newState = {...state};
        newState.mail.showDialog = false;
        setState(newState);
    }

    return (
        <>
            <Steps model={determineSteps()} activeIndex={state.step}
                   readOnly={false}
                   onSelect={(e) => setState({...state, step: e.index, stepsHistory: [...state.stepsHistory, e.item.label || '']})}/>
            <AvForm onValidSubmit={validSubmit}>
                <Container>
                    {getStepContent(state.step)}
                    <Row className="my-4">
                        {
                            !state.mail.sent &&
                            <>
                                <Col className="d-flex justify-content-end">
                                    <Button
                                        disabled={state.step === 0}
                                        onClick={handleBackStep}
                                        className="p-button-secondary"
                                    >
                                        Cofnij
                                    </Button>
                                </Col>
                                <Col className="d-flex">
                                    <Button
                                        type="submit"
                                        color="primary"
                                    >
                                        {state.step === determineSteps().length - 1 ? 'Wyślij' : 'Dalej'}
                                    </Button>
                                </Col>
                            </>
                        }
                        {
                            state.mail.sent &&
                            <Col>
                                <h5>Wiadomość wysłana pomyślnie!</h5>
                            </Col>
                        }
                    </Row>
                </Container>
            </AvForm>

            <Dialog header="Wysyłanie formularza" visible={state.mail.showDialog} style={{width: '50vw'}}
                    footer={renderDialogFooter()}
                    onHide={() => hideDialog()} blockScroll>
                {
                    state.mail.sending &&
                    <ProgressSpinner/>
                }
                {
                    !state.mail.sending && state.mail.sent &&
                    <Message severity="success" style={{width: "100%", fontWeight: "bold"}}
                             text="Pomyślnie wysłano formularz!"/>
                }
                {
                    !state.mail.sending && !state.mail.sent &&
                    <Message severity="error" style={{width: "100%", fontWeight: "bold"}}
                             text="Wystąpił błąd podczas wysyłania formularza. Spróbuj ponownie za moment!"/>
                }
            </Dialog>
        </>
    )
}