import React from 'react';
import {SelectButton} from 'primereact/selectbutton';
import {Col, Row} from "reactstrap";
import {AvGroup, AvInput, AvField} from "availity-reactstrap-validation";
import "./StairsTypeForm.css";
import {InputText} from "primereact/inputtext";

type FinishingFormProps = {
    finishing: string | undefined,
    onFinishingChange: (type: string) => void
}

export const FinishingForm: React.FC<FinishingFormProps> = ({finishing, onFinishingChange}) => {

    const finisingItems = [{
        label: "bejcowanie oraz lakierowanie",
        value: "bejcowanie oraz lakierowanie",
    }, {
        label: "lakierowanie",
        value: "lakierowanie",
    }, {
        label: "malowanie na kolor",
        value: "malowanie na kolor",
    }];

    const ifOptionsContains = (value: string | undefined): boolean => {
        return finisingItems.find(item => item.label === value) !== undefined;
    }

    return (
        <>
            <Row className="my-3">
                <Col>
                    <h2>Wybierz rodzaj wykończenia elementów drewnianych (opcjonalne)</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AvGroup>
                        <AvInput name="finishing"
                                 className="d-flex flex-column" tag={SelectButton}
                                 value={finishing}
                                 onChange={(e: { value: string; }) => onFinishingChange(e.value)}
                                 optionLabel="label"
                                 optionValue="value"
                                 options={finisingItems}
                        />
                    </AvGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AvField name="finishingOther"
                             label="Inny"
                             inputClass="form-control"
                             tag={InputText}
                             groupAttrs={{
                                 className: "m-0"
                             }}
                             validate={{
                                 pattern: {
                                     value: /^[A-Za-z0-9-/. ]*$/u,
                                     errorMessage: 'Niepoprawny rodzaj wykończenia!'
                                 },
                                 minLength: {value: 3, errorMessage: 'Minimalna długość to 3 znaki!'},
                                 maxLength: {value: 255, errorMessage: 'Maksymalna długość to 255 znaków!'},
                             }}
                             value={ifOptionsContains(finishing) ? '' : finishing}
                             onChange={(e: { target: { value: string; }; }) => onFinishingChange(e.target.value)}
                    />
                </Col>
            </Row>
        </>
    )
}