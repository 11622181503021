import React from 'react';
import {AvField} from 'availity-reactstrap-validation';
import {InputText} from "primereact/inputtext";
import {Row, Col} from 'reactstrap';

type ClientFormProps = {
    name: string | undefined,
    onNameChange: (name: string) => void,
    phone: string | undefined,
    onPhoneChange: (name: string) => void,
    email: string | undefined,
    onEmailChange: (name: string) => void
}


export const ClientForm: React.FC<ClientFormProps> = (props) => {

    return (
        <>
            <Row className="my-3">
                <Col>
                    <h2>Wprowadź dane kontaktowe</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AvField name="name"
                             label="Imie i nazwisko/Nazwa firmy*"
                             inputClass="form-control" tag={InputText}
                             value={props.name}
                             onChange={(e: { target: { value: string; }; }) => props.onNameChange(e.target.value)}
                             validate={{
                                 minLength: {value: 3, errorMessage: 'Minimalna długość to 3 znaki!'},
                                 maxLength: {value: 255, errorMessage: 'Maksymalna długość to 255 znaków!'},
                                 required: {value: true, errorMessage: 'Pole wymagane!'}
                             }}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AvField name="phone"
                             label="Telefon*"
                             inputClass="form-control" tag={InputText}
                             value={props.phone}
                             onChange={(e: { target: { value: string; }; }) => props.onPhoneChange(e.target.value)}
                             keyfilter={/^[\d+]$/}
                             validate={{
                                 pattern: {
                                     value: /^(?:(?:(?:\+|00)?48)|(?:\(\+?48\)))?(?:1[2-8]|2[2-69]|3[2-49]|4[1-68]|5[0-9]|6[0-35-9]|[7-8][1-9]|9[145])\d{7}$/u,
                                     errorMessage: 'Nieprawidłowy numer telefonu!'
                                 },
                                 required: {value: true, errorMessage: 'Pole wymagane!'}
                             }}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AvField name="email"
                             label="Email"
                             groupAttrs={{
                                 className: "m-0"
                             }}
                             inputClass="form-control m-0" tag={InputText}
                             value={props.email}
                             onChange={(e: { target: { value: string; }; }) => props.onEmailChange(e.target.value)}
                             validate={{
                                 email: {value: true, errorMessage: 'Nieprawidłowy adres e-mail!'}
                             }}/>
                </Col>
            </Row>
        </>
    )
}