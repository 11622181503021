import React from 'react';
import {InputText} from "primereact/inputtext";
import {Row, Col} from 'reactstrap';
import {FormModel} from "../api/FormModel";
import Label from "reactstrap/lib/Label";

type AddressFormReadonlyProps = {
    model: FormModel
}

export const AddressFormReadonly: React.FC<AddressFormReadonlyProps> = ({model}) => {

    return (
        <>
            <Row>
                <Col>
                    <Label>Adres</Label>
                    <InputText readOnly={true} className="form-control" value={model.address.addressLine}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>Adres</Label>
                    <InputText readOnly={true} className="form-control" value={model.address.postalCode}/>
                </Col>
                <Col>
                    <Label>Miejscowość</Label>
                    <InputText readOnly={true} className="form-control" value={model.address.city}/>
                </Col>
            </Row>
        </>
    )
}