export type Schody = {
    daneOsobowe: string;
    telefon: string;
    email: string | undefined;
    betonowe: null | SchodyBetonowe;
    samonosne: null | SchodySamonosne;
    spiralne: null | SchodySpiralne;
    rodzajDrewna: string | undefined;
    rodzajWykonczenia: string | undefined;
    balustrady: null | string;
    komentarz: null | string;
    numerZdjeciaGaleria: number | undefined
    adres: string;
}

export type SchodyBetonowe = {
    trybOkladania: string | undefined;
    iloscStopniProstych: number | undefined;
    dlugoscStopniProstych: number | undefined;
    szerokoscStopniProstych: number | undefined;
    iloscStopniZabiegowych: number | undefined;
    iloscPodestow: number | undefined;
}

export enum TypSamonosnych {
    Zab90 = "zab90", Zab180 = "zab180", Zab2x90 = "zab2x90", Zab3x90 = "zab3x90", Pod180 = "pod180", Proste = "proste"
}

export type OtworStropowyProstokatny = {
    dlugosc: number | undefined,
    szerokosc: number | undefined
}

export type OtworStropowyOkragly = {
    srednica: number | undefined
}

export type UkladScian = {
    lewo: boolean
    prawo: boolean
    gora: boolean
    dol: boolean
}

export enum TypPodstopni {
    Podstopnie = "z podstopniami", BezPodstopni = "bez podstopni"
}

export type SchodySamonosne = {
    typ: TypSamonosnych | undefined,
    wysokoscKondygnacji: number | undefined,
    gruboscStropu: number | undefined,
    otworProstokatny: null | undefined | OtworStropowyProstokatny;
    otworOkragly: null | undefined | OtworStropowyOkragly;
    otworInny: null | undefined | boolean;
    ukladScian: UkladScian | undefined;
    podstopnie: TypPodstopni | undefined;
}

export enum RodzajMetalu {
    stalNierdzewna = "stal nierdzewna", stalMalowanaProszkowo = "stal malowana proszkowo"
}

export interface SchodySpiralne {
    rodzajMetalu: RodzajMetalu | undefined;
    otworProstokatny: null | undefined | OtworStropowyProstokatny;
    otworOkragly: null | undefined | OtworStropowyOkragly;
    otworInny: null | undefined | boolean
    ukladScian: UkladScian | undefined;
}