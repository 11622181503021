import React from 'react';
import {SelectButton} from 'primereact/selectbutton';
import {Col, Row} from "reactstrap";
import {AvField, AvGroup, AvInput} from "availity-reactstrap-validation";
import {InputText} from "primereact/inputtext";

type CoveringFormProps = {
    covering: string | undefined,
    onCoveringChange: (type: string) => void
}

export const CoveringForm: React.FC<CoveringFormProps> = ({covering, onCoveringChange}) => {

    const availableTypes = [
        {
            label: "same stopnie",
            value: "same stopnie"
        },
        {
            label: "stopnie i podstopnie drewniane",
            value: "stopnie i podstopnie drewniane"
        },
        {
            label: "stopnie drewniane i podstopnie z płyty",
            value: "stopnie drewniane i podstopnie z płyty"
        },
        {
            label: "obłożenie w trybie dywanowym (stopień i podstopień równej grubości)",
            value: "obłożenie w trybie dywanowym (stopień i podstopień równej grubości)"
        }
    ]

    const ifOptionsContains = (value: string | undefined): boolean => {
        return availableTypes.find(item => item.label === value) !== undefined;
    }

    return (
        <>
            <Row className="my-3">
                <Col>
                    <h2>Wybierz tryb okładania (opcjonalne)</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AvGroup>
                        <AvInput name="covering"
                                 className="d-flex flex-column"
                                 tag={SelectButton}
                                 value={covering}
                                 onChange={(e: { value: string; }) => onCoveringChange(e.value)}
                                 optionLabel="label"
                                 optionValue="value"
                                 options={availableTypes}
                        />
                    </AvGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AvField name="coveringOther"
                             label="Inny"
                             inputClass="form-control"
                             tag={InputText}
                             groupAttrs={{
                                 className: "m-0"
                             }}
                             validate={{
                                 pattern: {
                                     value: /^[A-Za-z0-9-/. ]*$/u,
                                     errorMessage: 'Niepoprawny tryb okładania!'
                                 },
                                 minLength: {value: 3, errorMessage: 'Minimalna długość to 3 znaki!'},
                                 maxLength: {value: 255, errorMessage: 'Maksymalna długość to 255 znaków!'},
                             }}
                             value={ifOptionsContains(covering) ? '' : covering}
                             onChange={(e: { target: { value: string; }; }) => onCoveringChange(e.target.value)}
                    />
                </Col>
            </Row>
        </>
    )
}