import React from 'react';
import './App.css';
import {Form} from "./components/Form";
import axios from "axios";
import {config} from "./config";

axios.defaults.baseURL = config.apiUrl;
axios.defaults.withCredentials = false;

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <h1 className="py-4 m-0">Formularz wyboru schodów</h1>
                <hr className="mb-5"/>
            </header>
            <main>
                <Form/>
            </main>
        </div>
    );
}

export default App;
