import React from 'react';
import {Row, Col} from 'reactstrap';
import {FormModel} from "../api/FormModel";
import {ClientFormReadonly} from "./ClientFormReadonly";
import {AddressFormReadonly} from "./AddressFormReadonly";
import {StairsFormReadonly} from "./StairsFormReadonly";

type SummaryFormPageProps = {
    model: FormModel
}

export const SummaryFormPage: React.FC<SummaryFormPageProps> = ({model}) => {

    return (
        <>
            <Row className="mb-3">
                <Col>
                    <h2>Podsumowanie</h2>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <h5>Dane kontaktowe</h5>
                </Col>
            </Row>
            <ClientFormReadonly model={model}/>
            <Row className="my-3">
                <Col>
                    <h5>Schody</h5>
                </Col>
            </Row>
            <StairsFormReadonly model={model}/>
            <Row className="my-3">
                <Col>
                    <h5>Adres realizacji</h5>
                </Col>
            </Row>
            <AddressFormReadonly model={model}/>
        </>
    )
}