import React from 'react';
import {Col, Row} from "reactstrap";
import {AvField} from "availity-reactstrap-validation";
import {InputText} from "primereact/inputtext";

type DimensionFormProps = {
    numOfStraightGrades: number | undefined
    lengthOfStraightGrades: number | undefined
    widthOfStraightGrades: number | undefined
    numOfTreatmentSteps: number | undefined
    numOfPlatforms: number | undefined
    onNumOfStraightGradesChange: (numOfStraightGrades: number | undefined) => void
    onLengthOfStraightGradesChange: (lengthOfStraightGrades: number | undefined) => void
    onWidthOfStraightGradesChange: (widthOfStraightGrades: number | undefined) => void
    onNumOfTreatmentStepsChange: (numOfTreatmentSteps: number | undefined) => void
    onNumOfPlatformsChange: (numOfPlatforms: number | undefined) => void
}

export const DimensionForm: React.FC<DimensionFormProps> = (props) => {

    return (
        <>
            <Row className="my-3">
                <Col>
                    <h2>Wprowadź wymiary schodów (opcjonalne)</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AvField name="numOfStraightGrades"
                             label="Liczba stopni prostych"
                             keyfilter="pint"
                             inputClass="form-control" tag={InputText}
                             value={props.numOfStraightGrades ? props.numOfStraightGrades : ''}
                             onChange={(e: { target: { value: string; }; }) => {
                                 props.onNumOfStraightGradesChange(e.target.value ? parseFloat(e.target.value) : undefined)
                             }}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <AvField name="lengthOfStraightGrades"
                             label="Długość stopni prostych (cm)"
                             keyfilter={/^[\d,]$/}
                             inputClass="form-control" tag={InputText}
                             value={props.lengthOfStraightGrades ? props.lengthOfStraightGrades.toString().replace('.', ',') : ''}
                             onChange={(e: { target: { value: string; }; }) => props.onLengthOfStraightGradesChange(e.target.value ? parseFloat(e.target.value.replace(',', '.')) : undefined)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <AvField name="widthOfStraightGrades"
                             label="Szerokość stopni prostych (cm)"
                             keyfilter={/^[\d,]$/}
                             inputClass="form-control" tag={InputText}
                             value={props.widthOfStraightGrades ? props.widthOfStraightGrades.toString().replace('.', ',') : ''}
                             onChange={(e: { target: { value: string; }; }) => props.onWidthOfStraightGradesChange(e.target.value ? parseFloat(e.target.value.replace(',', '.')) : undefined)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <AvField name="numOfTreatmentSteps"
                             label="Liczba stopni zabiegowych"
                             keyfilter="pint"
                             inputClass="form-control" tag={InputText}
                             value={props.numOfTreatmentSteps ? props.numOfTreatmentSteps : ''}
                             onChange={(e: { target: { value: string; }; }) => props.onNumOfTreatmentStepsChange(e.target.value ? parseFloat(e.target.value) : undefined)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <AvField name="numOfPlatforms"
                             label="Liczba podestów"
                             keyfilter="pint"
                             groupAttrs={{
                                 className: "m-0"
                             }}
                             inputClass="form-control" tag={InputText}
                             value={props.numOfPlatforms ? props.numOfPlatforms : ''}
                             onChange={(e: { target: { value: string; }; }) => props.onNumOfPlatformsChange(e.target.value ? parseFloat(e.target.value) : undefined)}
                    />
                </Col>
            </Row>
        </>
    )
}