import axios from 'axios'
import {Schody} from "../api/FormDTO";

export const sendForm = (formDto: Schody, images: File[], sketches: File[], concreteConstructionImages: File[]) => {
    const formData = new FormData();
    formData.append('schody', JSON.stringify(formDto));
    images.forEach(image => {
        formData.append('zdjecia', image)
    });
    sketches.forEach(sketch => {
        formData.append('szkiceOtworu', sketch)
    });
    concreteConstructionImages.forEach(image => {
        formData.append('zdjeciaKonstrukcjiBetonowej', image)
    });
    return axios
        .post('/send',
            formData,
            {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            })
        .then(res => res.data);
}