import React from 'react';
import {SelectButton} from 'primereact/selectbutton';
import {Col, Row} from "reactstrap";
import {AvGroup, AvInput, AvField, AvFeedback} from "availity-reactstrap-validation";
import "./StairsTypeForm.css"
import {InputText} from "primereact/inputtext";

type WoodsTypeFormProps = {
    type: string | undefined,
    avalableTypes: Array<{ label: string, value: string, assetName: string }>,
    onTypeChange: (type: string) => void
}
export const WoodsTypeForm: React.FC<WoodsTypeFormProps> = ({type, avalableTypes, onTypeChange}) => {

    const ifOptionsContains = (value: string | undefined): boolean => {
        return avalableTypes.find(item => item.value === value) !== undefined;
    }

    const typeTemplate = (item: { label: string, value: string, assetName: string }) => {
        const logoPath = 'assets/' + item.assetName + '.jpg';
        return (
            <div className="p-3" style={{textAlign: 'center'}}>
                <img alt={item.label} src={logoPath} style={{width: '100%'}}/>
                <div className="mt-1">{item.label}</div>
            </div>
        )
    }

    return (
        <>
            <Row className="my-3">
                <Col>
                    <h2>Wybierz rodzaj drewna (opcjonalne)</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AvGroup>
                        <AvInput name="woodType"
                                 className="d-flex" tag={SelectButton}
                                 value={type}
                                 onChange={(e: { value: string; }) => onTypeChange(e.value ? e.value : '')}
                                 itemTemplate={typeTemplate}
                                 optionLabel="label"
                                 optionValue="value"
                                 options={avalableTypes}
                        />
                        <AvFeedback>Wymagane wybranie opcji lub wprowadzenie!</AvFeedback>
                    </AvGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AvField name="woodTypeOther"
                             label="Inny"
                             groupAttrs={{
                                 className: "m-0"
                             }}
                             inputClass="form-control"
                             tag={InputText}
                             validate={{
                                 pattern: {
                                     value: /^[A-Za-z0-9-/. ]*$/u,
                                     errorMessage: 'Niepoprawny rodzaj drewna!'
                                 },
                                 minLength: {value: 3, errorMessage: 'Minimalna długość to 3 znaki!'},
                                 maxLength: {value: 255, errorMessage: 'Maksymalna długość to 255 znaków!'},
                             }}
                             value={ifOptionsContains(type) ? '' : type}
                             onChange={(e: { target: { value: string; }; }) => onTypeChange(e.target.value)}
                    />
                </Col>
            </Row>
        </>
    )
}