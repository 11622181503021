import React from 'react';
import {SelectButton} from 'primereact/selectbutton';
import {Col, Row} from "reactstrap";
import {AvGroup, AvInput, AvFeedback, AvField} from "availity-reactstrap-validation";
import "./StairsTypeForm.css"
import {Type} from "../api/FormModel";

type StairsTypeFormProps = {
    type: Type | undefined,
    onTypeChange: (type: Type) => void
}

export const StairsTypeForm: React.FC<StairsTypeFormProps> = ({type, onTypeChange}) => {

    const availableTypes = Object.keys(Type).map(key => {
        return {
            label: Type[key as keyof typeof Type],
            value: key
        }
    });

    const typeTemplate = (item: { label: string, value: string }) => {
        const logoPath = 'assets/' + item.value.toLowerCase() + '.jpg';
        return (
            <div className="p-3" style={{textAlign: 'center'}}>
                <img alt={item.label} src={logoPath} style={{width: '100%'}}/>
                <div className="mt-1">{item.label}</div>
            </div>
        )
    }

    return (
        <>
            <Row className="my-3">
                <Col>
                    <h2>Wybierz typ schodów</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AvGroup style={{margin: 0}}>
                        <AvInput name="stairsType"
                                 label="Typ schodów*"
                                 required
                                 className="d-flex" tag={SelectButton}
                                 value={type}
                                 onChange={(e: { value: Type; }) => onTypeChange(e.value)}
                                 itemTemplate={typeTemplate}
                                 optionLabel="label"
                                 optionValue="value"
                                 options={availableTypes}
                        />
                        <AvFeedback>Pole wymagane!</AvFeedback>
                    </AvGroup>
                </Col>
            </Row>
        </>
    )
}