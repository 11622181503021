import React from 'react';
import {SelectButton} from 'primereact/selectbutton';
import {Col, Row} from "reactstrap";
import {AvGroup, AvInput, AvFeedback} from "availity-reactstrap-validation";
import "../StairsTypeForm.css"

type RisersFormProps = {
    risers: boolean | undefined,
    onRisersChange: (risers: boolean | undefined) => void
}

export const RisersForm: React.FC<RisersFormProps> = ({risers, onRisersChange}) => {

    const availableOptions: Array<{ label: string, value: string }> = [
        {
            label: "z podstopniami",
            value: "true",
        },
        {
            label: "bez podstopni",
            value: "false",
        }
    ]

    const riserTemplate = (item: { label: string, value: string }) => {
        const logoPath = 'assets/' + (item.value === 'true' ? 'z_podstopniami' : 'bez_podstopni') + '.png';
        return (
            <div className="p-3" style={{textAlign: 'center'}}>
                <img alt={item.label} src={logoPath} style={{width: '100%', maxWidth: 350}}/>
                <div className="mt-1">{item.label}</div>
            </div>
        )
    }

    return (
        <>
            <Row className="my-3">
                <Col>
                    <h2>Wybierz podstopnie (opcjonalne)</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AvGroup className="m-0">
                        <AvInput name="stairsType"
                                 className="d-flex justify-content-center"
                                 tag={SelectButton}
                                 value={risers === undefined ? undefined : risers.toString()}
                                 onChange={(e: { value: string; }) => {
                                     onRisersChange(e.value === null ? undefined : e.value === 'true');
                                 }}
                                 itemTemplate={riserTemplate}
                                 optionLabel="label"
                                 optionValue="value"
                                 options={availableOptions}
                        />
                    </AvGroup>
                </Col>
            </Row>
        </>
    )
}